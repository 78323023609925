import clsx from 'clsx';
import { graphql } from 'gatsby';
import { Trans } from 'gatsby-plugin-react-i18next';
import { find } from 'lodash';
import React from 'react';
import EnterpriseForm from '../../components/EnterpriseForm/EnterpriseForm';
import { Seo } from '../../components/Seo';
import Claim from '../../components/UI/Claim/Claim';
import Layout from '../../layouts/layout';

import * as classes from './enterprise.module.scss';

const EnterprisePage = props => {
    return <Layout location={props.location}>
        <div className="container">
            <div className={clsx(classes.Enterprise, 'row')}>
                <div className={clsx('col-md-6', classes.Claim)}>
                    <h1 className={classes.Title}><Trans>AIDA Enterprise plan</Trans></h1>
                    <h2 className={classes.Subtitle}><Trans>your unlimited and custom version of our Artificial
                                                            Intelligence</Trans></h2>
                    <div className={classes.CallToAction}>
                        <Claim />
                    </div>
                </div>
                <div className={clsx('col-md-6', classes.FormContainer)}>
                    <EnterpriseForm />
                </div>
            </div>
        </div>
    </Layout>;
};

EnterprisePage.propTypes = {};

export default EnterprisePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
        filter: {ns: {in: ["common", "enterprise-page"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({ data }) => {
    const edge = find(data.locales.edges, [ 'node.ns', 'enterprise-page' ]);
    let obj = undefined;
    if (!!edge) {
        obj = JSON.parse(edge.node.data);
    }
    const title = !!obj ? obj.title : 'Enterprise plan';

    return <Seo title={title} description={!!obj ? obj.description : null} />;
};
