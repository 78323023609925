import { useI18next } from 'gatsby-plugin-react-i18next';
import { sample } from 'lodash';
import { useEffect, useState } from 'react';

const CLAIMS = [
    'common:contacts.call.automatedTimeDay',
    'common:contacts.call.productivityBoost',
    'common:contacts.call.roi'
];

const Claim = () => {
    const { t } = useI18next();
    const [ message, setMessage ] = useState(null);

    useEffect(() => {
        /* i18next-extract-disable-next-line */
        setMessage(t(sample(CLAIMS)));
    }, [ t ]);

    return message;
};

export default Claim;
